// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  backendEndpoint: 'https://appwrite.odokus.info/v1',
  appWriteProjectId: '62a170a17dc54fe2d46d',
  backendDatabaseID: 'default',
  eGrainNodeDataCollection: '62e368f6e53b52c22498',
  eGrainDeviceCollection: '62f0e35621e9e748dab8',
  eGrainManagementZones: '62f135c2988c1784b7a4',
  backendDatabaseFetchDocBatchSize: 100,/*max 100*/
  openWeatherAPIkey: '0c0517152cc377b89563d814a9c6cb79', // API KEY by J.D. !!!
};

export const odokusApiEndpoint =
  {
    url: 'https://developer.odokus.de/odokus2/json-rpc',
    test: 'test result'
  };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
