import {AuthConfig} from 'angular-oauth2-oidc';

export const authConfig: AuthConfig = {

  // URL of the SPA to redirect the user to after login
  redirectUri: window.location.origin + '/create-qr',

  // The SPA's id. The SPA is registered with this id at the auth-server
  issuer: 'https://sensorsystems.iais.fraunhofer.de/api',
  clientId: '',//'atlas_Hansenhof _electronic',
  dummyClientSecret: '', //'ccdf7e2a-949a-44fd-ac42-5cd76ac8fb20',
  disablePKCE: false,
  tokenEndpoint: 'https://sensorsystems.iais.fraunhofer.de/api/auth/349ad829-82e2-4f91-9e45-b3df10fa5b33/com.agricircle.atlas.field_data/token',
  loginUrl: 'https://sensorsystems.iais.fraunhofer.de/api/auth/349ad829-82e2-4f91-9e45-b3df10fa5b33/com.agricircle.atlas.field_data/auth',
  responseType: 'code',
  requireHttps: false,

  showDebugInformation: true,
  // set the scope for the permissions the client should request
  // The first three are defined by OIDC. The 4th is a usecase-specific one
  scope: 'offline_access',
}



/*
*
// get Bearer Token from ATLAS
curl -X POST 'https://sensorsystems.iais.fraunhofer.de/auth/realms/participants/protocol/openid-connect/token' \
-u 'atlas_Hansenhof _electronic:ccdf7e2a-949a-44fd-ac42-5cd76ac8fb20' \
-d 'grant_type=client_credentials'


// get field_data Service vendors...
curl -X GET 'https://sensorsystems.iais.fraunhofer.de/api/services?template_name=field_data' \
-H "Authorization: Bearer {{access_token}}"



*
*
*
* */
