import { Injectable } from '@angular/core';
import {Client, Account, Databases, Models, Avatars} from 'appwrite';
import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import {Observable} from 'rxjs';
import {resolve} from '@angular/compiler-cli';
import {UserPreferences} from "../userprofile/userprofile.page";



const client = new Client();
const account = new Account(client);
const databases = new Databases(client, environment.backendDatabaseID);
const avatars = new Avatars(client);

client
  .setEndpoint(environment.backendEndpoint)
  .setProject(environment.appWriteProjectId);



@Injectable({
  providedIn: 'root'
})
export class AppwriteServiceService {

  constructor(private router: Router) {
  }



  // Register a new User
  register(value): Observable<any> {

    return new Observable<any>(
      (observer) => {
        localStorage.setItem('name', value.name);
        const promise = account.create('unique()', value.email, value.password, value.name);
        promise.then((response)=> {
          //console.log(response); // Success
          observer.next(true);
        }, (error) => {
          //console.log(error); // Failure
          observer.error(false);
        });
      }
    );
  }



  // Log User In (Create userSession)
  public appwriteLogin(user: string, pass: string): Promise<any> {
    const p = new Promise((resolve, reject) => {
      const aLoginPromis = account.createEmailSession(user, pass);
      aLoginPromis.then((response) => {
        //console.log( 'Login response: ');
        //console.log(response);
        localStorage.setItem('mailAddress', user);
        this.getUsersProfile().then(profileResponse => {
          console.log('Stored users mail Address in local storage.');
          console.log(profileResponse);
        }, (profileReject) => {
          console.log(profileReject);
        });
        resolve('Login erfolgreich');
      }, (error) => {
        //console.log('Appwrite Service implementation failed to log user in: ' + error);
        //console.log(error); // Failure
        reject(error.toString());
      });
    });
    return p;
  }


  // Log User out (delete all sessions)
  public appwriteLogout() {
    localStorage.removeItem('cookieFallback');
    const promise = account.deleteSessions();
    promise.then(response => {
      //console.log(response); // Success
      //console.log('Logged out from backend');
      this.router.navigateByUrl('/login').then(r => {
        //console.log('navigating to login page');
      });
    }, (error) => {
      //console.log(error); // Failure
    });
  }



  // Get Users Profile
  public getUsersProfile(): Promise<any>{
    const p = new Promise(async (resolve, reject) => {
      try {
        const user = await account.get();
        //console.log('Received User Profile.');
        //console.log(user);
        localStorage.setItem('name', user.name);
        resolve(user);
      } catch (e) {
        reject('Error fetching users profile from appwrite.');
      }
    });
    return p;
  }



  // Get User Preferences
  public getUserPreferences(): Promise<any>{
    const p = new Promise(async (resolve, reject) => {
      try {
        const prefs = await account.getPrefs();
        console.log('Received User Preferences.');
        console.log(prefs);
        //localStorage.setItem('name', user.name);
        resolve(prefs);
      } catch (e) {
        console.log(e);
        reject('Error fetching users preferences from appwrite.');
      }
    });
    return p;
  }



  // Set User Preferences
  public setUserPreferences(newPreferences: UserPreferences): Promise<any>{
    const p = new Promise(async (resolve, reject) => {
      try {
        const prefs = await account.updatePrefs(newPreferences);
        console.log('Updating User Preferences.');
        console.log(prefs);
        //localStorage.setItem('name', user.name);
        resolve(prefs);
      } catch (e) {
        console.log(e);
        reject('Error updating users preferences to appwrite.');
      }
    });
    return p;
  }



  // Get Users Avatar
  public getUsersAvatar(name: string, height: number, width: number): Promise<any>{
    const p = new Promise(async (resolve, reject) => {
      try {
        const avatar = await avatars.getInitials(name, width, height, 'white', 'blue');
        //console.log('Received User Avatar.');
        //console.log(avatar);
        resolve(avatar);
      } catch (e) {
        reject('Error fetching users avatar from appwrite.');
      }
    });
    return p;
  }



  public getBackendDocuments(aCollectionId: string, batchSize: number): Observable<any>{
    if (batchSize > 100 /*max limit of API = 100*/)
    {
      //console.log('Requested batch size of ' + batchSize + ' is too large. Reducing size to 100.');
      batchSize = 100;
    }
    return new Observable<any>(
      (observer) => {
        // Fetch initial batch of documents and find out if we received all or just a first batch of them.
        //console.log('Fetching initial batch of documents from database');
        databases.listDocuments(aCollectionId, [], batchSize /*max 100 possible*/).then(async (value) => {
          // success: we received documents.
          const totalNumOfDocuments: number = value.total;
          //console.log('There are total No. of ' + totalNumOfDocuments + ' data records in database.');
          let numOfReceivedDocuments: number = value.documents.length;
          //console.log('We received the first ' + numOfReceivedDocuments + ' documents.');
          let restToFetch: number = (totalNumOfDocuments - numOfReceivedDocuments);
          //console.log('So, we do we need to fetch more? --> ' + restToFetch);
          observer.next(value); // notify subscriber about the already received ones.


          // if there are more to fetch, fetch in loop until all have been received.
          while (restToFetch > 0) {
            //console.log('Looping to fetch the rest... (' + restToFetch + ').');
            try {
              const data = await databases.listDocuments(aCollectionId, [], batchSize, numOfReceivedDocuments);
              observer.next(data);
              numOfReceivedDocuments = numOfReceivedDocuments + data.documents.length;
              restToFetch = totalNumOfDocuments - numOfReceivedDocuments;
            } catch (e) {
              //console.log(e);
              restToFetch = 0;
              observer.error(e);
            }
          }
          observer.complete();
        }).catch((reason) => {
          // error occured
          //console.log(reason);
          observer.error(reason);
        });
      });
  }



  public getBackendDocumentsWithQueries(aCollectionId: string, batchSize: number, queries: string[]): Observable<any>{
    if (batchSize > 100 /*max limit of API = 100*/)
    {
      //console.log('Requested batch size of ' + batchSize + ' is too large. Reducing size to 100.');
      batchSize = 100;
    }
    return new Observable<any>(
      (observer) => {
        // Fetch initial batch of documents and find out if we received all or just a first batch of them.
        //console.log('Fetching initial batch of documents from database');
        databases.listDocuments(aCollectionId, queries, batchSize /*max 100 possible*/).then(async (value) => {
          // success: we received documents.
          const totalNumOfDocuments: number = value.total;
          //console.log('There are total No. of ' + totalNumOfDocuments + ' data records in database.');
          let numOfReceivedDocuments: number = value.documents.length;
          //console.log('We received the first ' + numOfReceivedDocuments + ' documents.');
          let restToFetch: number = (totalNumOfDocuments - numOfReceivedDocuments);
          //console.log('So, we do we need to fetch more? --> ' + restToFetch);
          observer.next(value); // notify subscriber about the already received ones.


          // if there are more to fetch, fetch in loop until all have been received.
          while (restToFetch > 0) {
            //console.log('Looping to fetch the rest... (' + restToFetch + ').');
            try {
              const data = await databases.listDocuments(aCollectionId, queries, batchSize, numOfReceivedDocuments);
              observer.next(data);
              numOfReceivedDocuments = numOfReceivedDocuments + data.documents.length;
              restToFetch = totalNumOfDocuments - numOfReceivedDocuments;
            } catch (e) {
              //console.log(e);
              restToFetch = 0;
              observer.error(e);
            }
          }
          observer.complete();
        }).catch((reason) => {
          // error occured
          //console.log(reason);
          observer.error(reason);
        });
      });
  }



  // Create a new Document
  createNewDocument(newData: any, collectionID: string): Observable<any> {
    return new Observable<any>(
      (observer) => {

        databases.createDocument(collectionID, 'unique()', newData).then(response => {
          //console.log(response); // Success
          observer.next(true);
        }, error => {
          //console.log(error); // Failure
          observer.error(false);
        });
      }
    );
  }


  // Delete a known Document
  async deleteDatabaseDocument(documentId: string, collectionID: string): Promise<any> {
    return new Promise((resolve, reject) => {
      databases.deleteDocument(collectionID, documentId).then(res => {
        resolve(res);
      }, fail => {
        reject(fail);
      });
    });
  }


}
