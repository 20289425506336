import { Component } from '@angular/core';
import {AppwriteServiceService} from './services/appwrite-service.service';
import {Router} from '@angular/router';

import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc-jwks';
import { authConfig } from './userprofile/auth.config';
import {HttpClient} from '@angular/common/http';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  public appPages = [
    { title: 'Dashboard', url: '/dashboard', icon: 'home' },
    { title: 'Karte', url: '/bigmap', icon: 'map' },
    { title: 'Profil', url: '/userprofile', icon: 'person' },
    { title: 'QR Generator', url: '/create-qr', icon: 'qr-code' },
  ];

  public rootPage: any;

  public labels = [  ];

  public atlasOauthAuthConfig = authConfig;

  constructor(
    private appwriteService: AppwriteServiceService,
    private oauthService: OAuthService,
    private router: Router,
    private httpClient: HttpClient,
  )
  {
    this.configure();
  }

  private async fetchIdAndToken()
  {

      const id = await this.httpClient.get('https://atlas-auth.odokus.info/clientid').toPromise();
      console.log('AppComponent fetched ClientID from Server: ' + id);
      const secret = await this.httpClient.get('https://atlas-auth.odokus.info/clientsecret').toPromise();
      console.log('AppComponent fetched ClientSecret from Server: ' + secret);
  }

    private configure() {

      this.fetchIdAndToken();

      console.log('JD: App.Component - OAuth configure()');
      this.oauthService.configure(this.atlasOauthAuthConfig);

      this.oauthService.tokenValidationHandler = new JwksValidationHandler();
  }


  public logoutUser(){
    this.oauthService.logOut();
    this.appwriteService.appwriteLogout();
    this.router.navigateByUrl('/login');
  }

}





