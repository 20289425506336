import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'bigmap',
    loadChildren: () => import('./bigmap/bigmap.module').then( m => m.BigmapPageModule)
  },
  {
    path: 'logout',
    loadChildren: () => import('./logout/logout.module').then( m => m.LogoutPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'userprofile',
    loadChildren: () => import('./userprofile/userprofile.module').then( m => m.UserprofilePageModule)
  },
  {
    path: 'myscans',
    loadChildren: () => import('./myscans/myscans.module').then( m => m.MyscansPageModule)
  },
  {
    path: 'qrscanner',
    loadChildren: () => import('./qrscanner/qrscanner.module').then( m => m.QrscannerPageModule)
  },
  {
    path: 'scandetails',
    loadChildren: () => import('./scandetails/scandetails.module').then( m => m.ScandetailsPageModule)
  },
  {
    path: 'create-qr',
    loadChildren: () => import('./create-qr/create-qr.module').then( m => m.CreateQRPageModule)
  },
  {
    path: 'fdx7test',
    loadChildren: () => import('./fdx7test/fdx7test.module').then( m => m.Fdx7testPageModule)
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
